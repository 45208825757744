import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import Page from '@/components/Page'
import PageWrapper from '@/components/PageWrapper'
import TextIntro from '@/components/TextIntro'
import { pageFadeInOut } from '@/modules/animationVariants'

const Index: React.FC = () => {
  const { t } = useTranslation()

  // first argument is a key from the sheet, second is a fallback
  // the "." is the key separator for nested values
  // always fill the fallback with something sensible please
  // console.log(t('home.home_header', 'Hello World'))

  return (
    <Page
      content={
        <PageWrapper>
          <TextIntro
            titleText={t('home.home_header', 'fill copy key: home.home_header')}
            paragraphText={t('home.home_body', 'fill copy key: home.home_body')}
          />
        </PageWrapper>
      }
      settings={{
        backgroundColor: '#5a7d69',
        contentAnimationVariants: pageFadeInOut(),
        useCircularWipe: false,
      }}
    />
  )
}

export default Index
